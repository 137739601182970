<template>
    <div class="user-invention-list">
        <div class="block-title">
            <div class="dot" />
            {{ $t('user.UserInventionList.title') }}
        </div>
        <div class="current">
            <div class="block-sub-title">
                {{ $t('user.UserInventionList.subtitle') }}
            </div>
            <div class="current-table-block apply-table">
                <v-data-table
                    :headers="currentHeaders"
                    :items="current"
                    :disable-pagination="true"
                    :disable-filtering="true"
                    :hide-default-footer="true"
                    :disable-sort="true"
                    :no-data-text="$t('user.UserInventionList.nodata')"
                    class="current-table"
                >
                    <template v-slot:item.status="{ item }">
                        <div
                            v-if="item.status == 'draft'"
                            class="edit-btn action-btn"
                            @click="editItem(item)"
                        >
                            {{ $t('user.UserInventionList.draft') }}
                        </div>
                        <div
                            v-if="item.status == 'submit'"
                            class="apply-btn action-btn"
                        >
                            {{ $t('user.UserInventionList.submit') }}
                        </div>
                    </template>
                </v-data-table>
            </div>
        </div>
    </div>
</template>

<script>
import ApplyMixin from '@/mixins/ApplyMixin.vue';
import API from '@/api';
import { mapMutations } from 'vuex';
import _ from 'lodash';

export default {
    components: {},
    mixins: [ApplyMixin],
    data() {
        return {
            session: '',
            currentHeaders: [{
                text: this.$t('user.UserInventionList.tabel-headers[0]'),
                align: 'start',
                value: 'team.name',
            }, {
                text: this.$t('user.UserInventionList.tabel-headers[1]'),
                align: 'start',
                value: 'session',
            }, {
                text: this.$t('user.UserInventionList.tabel-headers[2]'),
                align: 'start',
                value: 'status',
            }],
            current: [],
            list: [],
        };
    },
    computed: {},
    created() {
        this.init();
    },
    mounted() { },
    methods: {
        ...mapMutations('mNotification', ['handleError']),
        ...mapMutations('mLoading', ['setLoading']),
        init() {
            this.getList();
        },
        async getList() {
            this.setLoading(true);
            try {
                const res = await API.Setting.PublicInfo();
                this.session = res.data.InventionSession.session;
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.session-error'),
                    color: 'error',
                });
            }
            API.InventionApply
                .PrivateList({
                    params: {
                        limit: 10, // default: 10 可不帶
                        offset: 0, // 從第幾比開始取
                    },
                })
                .then((res) => {
                    // console.log('more', res.data.more);
                    // console.log('raw', res.data.raw);
                    // console.log('total', res.data.total);

                    this.list = res.data.raw;
                    this.filter();
                    this.setLoading(false);
                })
                .catch((e) => {
                    this.setLoading(false);
                    this.handleError({
                        message: e || this.$t('alertMessage.getdata-fail'),
                        color: 'error',
                    });
                });
        },
        filter() {
            this.current = _.filter(this.list, (o) => o.session === this.session);
        },
        editItem(item) {
            this.$router.push({ name: 'InventionTeamData', params: { id: item.id } });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/user-apply.scss';

.user-invention-list {
  .v-data-table__wrapper{
    .v-data-table-header {
    th {
      > span {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.1em;
        color: $color-orange;
      }
    }
  }

  tbody {
    td {
      font-size: 16px !important;
      font-weight: 700;
      letter-spacing: 0.1em;
      color: $color-blue;
    }
  }
  }

}
</style>
